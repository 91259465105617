import React, { useCallback, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import { createInstruction } from "../../services/instruction";
import CustomEditor from "../../components/editor/CustomEditor";
import Input from "../../components/UI/Input";
import DragDropFile from "../../components/DragDropFile";
import ImageCropper from "../../components/Cropper";
import Select from "../../components/UI/Select";
import Loader from "../../components/UI/Loader";
import Button from "../../components/UI/Button";

const InstructionCreate = () => {
  const navigate = useNavigate();
  const [editImageBanner, setEditImageBanner] = useState({
    show: false,
    data: [],
  });
  const {
    control,
    register,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      type: "instruction",
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const data = useWatch({ control });

  const onSubmit = useCallback(() => {
    setIsLoading(true);
    createInstruction(data)
      .then(() => {
        NotificationManager.success("Инструкция успешно создана");
        navigate(-1);
        setIsLoading(false);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
        setIsLoading(false);
      });
  }, [data]);

  return (
    <Container fluid={true}>
      <Meta title="Создать инструкцию" />
      <section className="box">
        <div className="d-flex justify-content-between align-items-center">
          <Link
            to="/instructions"
            className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
          >
            <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
          </Link>
          <Button onClick={() => onSubmit()} isLoading={isLoading} disabled={!isValid || isLoading}>Сохранить изменения</Button>
        </div>
        <h3 className="mb-4">Создать инструкцию</h3>
        <div className="mb-3">
          <Select
            label="Тип"
            data={[
              { title: "Инструкция", value: "instruction" },
              { title: "Партнер", value: "partner" },
            ]}
            value={data.type}
            onClick={(e) => setValue("type", e.value)}
          />
        </div>
        <div className="mb-3">
          <DragDropFile
            title={
              !data.type || data.type == "instruction"
                ? "Выбрать видео"
                : "Выбрать изображение"
            }
            file={data.media}
            accept={
              !data.type || data.type == "instruction" ? "video/*" : false
            }
            multiple={false}
            onRemove={() => {
              setValue("file", null);
              setValue("media", null);
            }}
            onChange={(e) => {
              if (data.type === "instruction") {
                // Проверяем, что выбранный файл - это видео
                const file = e; // Предполагаем, что e - это массив файлов
                if (file && file.type.startsWith("video/")) {
                  setValue("file", file);
                  setValue("media", URL.createObjectURL(file));
                }
              } else {
                setEditImageBanner((info) => ({
                  show: !info.show,
                  data: e,
                }));
              }
            }}
          />
          {data.type == "partner" && (
            <ImageCropper
              file={editImageBanner.data[0]}
              show={editImageBanner.show}
              aspect={265 / 149}
              setShow={(e) =>
                setEditImageBanner((info) => ({ ...info, show: e }))
              }
              onComplete={(e) => {
                e && setValue("file", e.file);
                e && setValue("media", e.blob);
              }}
            />
          )}
        </div>
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <Input
                label="Название"
                name="title"
                placeholder="Заголовок"
                errors={errors}
                register={register}
                validation={{
                  required: "Обязательное поле",
                }}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Input
                defaultValue={0}
                label="Порядок"
                name="priority"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3">
              <Input
                label="Ссылка"
                name="link"
                placeholder="Введите ссылку"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
        </Row>
        <CustomEditor
          content={data.desc}
          onChange={(e) => setValue("desc", e)}
        />
      </section>
    </Container>
  );
};

export default InstructionCreate;
