import moment from "moment";
import React, { useCallback, useLayoutEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import {
  IoChevronBackOutline,
  IoTrashOutline
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import { getHistories } from "../../services/history";
import { createTransaction } from "../../services/transaction";
import {
  blockedUser,
  deleteUser,
  editUser,
  getSessions,
  getUser
} from "../../services/user";
import Reports from "../task/Report";
import { useSelector } from "react-redux";
import { FaTelegram } from "react-icons/fa";
import { HiMiniCheckCircle } from "react-icons/hi2";

const EditUser = () => {
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [blockedShow, setBlockedShow] = useState(false);
  const [total, setTotal] = useState();
  const [modalSessionDelete, setModalSessionDelete] = useState({
    show: false,
    id: false,
  });
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });
  const [sessions, setSessions] = useState({ loading: false, items: [] });
  const [histories, setHistories] = useState({ loading: false, items: [] });

  const role = useSelector((state) => state?.auth?.user?.role);
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });
  const form = useWatch({ control });

  const {
    control: controlBlocked,
    register: registerBlocked,
    formState: { errors: errorsBlocked, isValid: isValidBlocked },
    handleSubmit: handleSubmitBlocked,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: { id: userId },
  });
  const formBlocked = useWatch({ control: controlBlocked });

  const sessionColumns = [
    {
      name: "Браузер",
      selector: "brand",
    },
    {
      name: "Устройство",
      selector: "osName",
    },
    {
      name: "Версия",
      selector: "osVersion",
    },
    {
      name: "IP",
      selector: "ip",
    },
    { name: "Последний вход", cell: (row) => moment(row.updatedAt).fromNow() },
    {
      width: "50px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <a
            onClick={() =>
              setModalSessionDelete({
                show: !modalSessionDelete.show,
                id: row.id,
              })
            }
          >
            <IoTrashOutline size={20} className="text-danger" />
          </a>
        </div>
      ),
    },
  ];

  const getData = () => {
    getUser(userId)
      .then((res) => reset(res))
      .finally(() => setLoading(false));
  };

  const getDataSessions = () => {
    getSessions({ userId })
      .then((res) => setSessions({ items: res, loading: false }))
      .catch(() => setSessions((data) => ({ ...data, loading: false })));
  };

  const getDataHistories = () => {
    getHistories({ userId })
      .then((res) => setHistories({ loading: false, ...res }))
      .catch(() => setHistories((data) => ({ ...data, loading: false })));
  };

  useLayoutEffect(() => {
    getData();
    getDataSessions();
    getDataHistories();
  }, [userId]);

  const onSubmit = useCallback((data) => {
    editUser(data)
      .then(() => NotificationManager.success("Данные успешно обновлены"))
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при сохранении"
        )
      );
  }, []);

  const onCash = useCallback(() => {
    if (!total) {
      return NotificationManager.error("Введите сумму")
    }
    createTransaction({ userId, total })
      .then(() => {
        NotificationManager.success("Данные успешно обновлены")
        setTotal("")
        getData()

      })
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при сохранении"
        )
      );
  }, [total, userId]);

  const onSubmitBlocked = useCallback(
    (data) => {
      const lastStatus = form.status;
      blockedUser({ id: form.id, status: data, blockedEnd: formBlocked?.blockedEnd })
        .then(() => {
          NotificationManager.success(
            data === 1
              ? lastStatus == 0
                ? "Пользователь верифицирован"
                : "Пользователь разблокирован"
              : "Пользователь заблокирован"
          );
          getData();
          form.status !== -1 && setBlockedShow(false);
        })
        .catch((err) =>
          NotificationManager.error(
            err?.response?.data?.error ?? "Ошибка при сохранении"
          )
        );
    },
    [form, formBlocked]
  );
  const onDelete = useCallback((id) => {
    deleteUser(id)
      .then(() => {
        NotificationManager.success("Аккунт успешно удален");
        setModalDelete({ show: false, id: false });
        navigate(-1);
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);


  if (loading) {
    return <Loader full />;
  }

  if (!form?.id) {
    return (
      <>
        <Meta title="Редактировать пользователя" />
        <Info>
          <svg
            className="mb-3"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.32"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
              fill="#999"
            />
            <path
              d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
              fill="#999"
            />
          </svg>
          <h3>Такого пользователя не существует</h3>
        </Info>
      </>
    );
  }

  return (
    <Container fluid={true}>
      <Meta title="Редактировать пользователя" />
      <section className="box">
        <div>
          <Link
            to="/users"
            className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
          >
            <IoChevronBackOutline className="me-2" size={18} /> Назад к
            пользователям
          </Link>
        </div>

        <div className="d-flex align-items-start mb-3">
          <div className="pe-3 mt-1">
            <div className="position-relative">
              {(form?.status === -1 || form?.blockedEnd) && (
                <div className="blocked-avatar">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.32"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      fill="#FF0000"
                      d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
                    />
                    <path
                      fill="#FF0000"
                      d="M18.6038 3.01136C19.2189 2.39628 20.2161 2.39628 20.8312 3.01136C21.4463 3.62643 21.4463 4.62367 20.8312 5.23874L5.23947 20.8304C4.6244 21.4455 3.62716 21.4455 3.01209 20.8304C2.39701 20.2154 2.39701 19.2181 3.01209 18.6031L18.6038 3.01136Z"
                    />
                  </svg>
                </div>
              )}
              <a
                href={process.env.REACT_APP_SITE_URL + "/user/" + form.id}
                target="_blank"
                className="user-avatar"
              >
                {form?.data?.telegramChatId ?
                  <FaTelegram size={15} className="avatar-verified" />
                  :
                  form?.status == 1 && (
                    <HiMiniCheckCircle size={15} className="avatar-verified" />
                  )}
                <img
                  src={getImageURL({ path: form?.media, type: "user" })}
                  width={45}
                  height={45}
                />
              </a>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-between">
            <div>
              <p className="fw-6">{form?.firstName ?? "Не указано"}</p>
              <p className="text-muted fs-08">
                Зарегистирован{" "}
                {moment(form?.createdAt).format("DD MMMM YYYY kk:mm") ??
                  "Email не указан"}
              </p>
              <p className="text-primary fs-08">ID - {form?.id}</p>
            </div>
            <div className="d-flex flex-column justify-content-between align-items-end">
              <span className="fw-6 d-flex align-items-center fs-09">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M15.5237 19.0041C15.3637 19.0048 15.206 18.967 15.0637 18.8941L9.96366 16.2241L4.86366 18.8941C4.52579 19.0718 4.11625 19.0416 3.80808 18.8163C3.49992 18.591 3.34689 18.21 3.41366 17.8341L4.41366 12.2041L0.293656 8.20411C0.0317118 7.94271 -0.0644601 7.55802 0.0436555 7.20411C0.1619 6.84153 0.476078 6.57778 0.853656 6.52411L6.55366 5.69411L9.06366 0.56411C9.23074 0.21912 9.58033 0 9.96366 0C10.347 0 10.6966 0.21912 10.8637 0.56411L13.4037 5.68411L19.1037 6.51411C19.4812 6.56778 19.7954 6.83153 19.9137 7.19411C20.0218 7.54802 19.9256 7.93271 19.6637 8.19411L15.5437 12.1941L16.5437 17.8241C16.6165 18.2069 16.4604 18.5972 16.1437 18.8241C15.9626 18.951 15.7445 19.0143 15.5237 19.0041Z"
                    fill="#ffc107"
                  />
                </svg>
                &nbsp;{form?.rating > 0 ? form.rating : 0}
              </span>
            </div>
          </div>
        </div>
        <div>
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td>ID:</td>
                <td>{form?.id}</td>
              </tr>
              {(form.data?.type == "LLC" || form.data?.type == "IP") && (
                <tr>
                  <td>Компания:</td>
                  <td>{form?.data?.companyName}</td>
                </tr>
              )}
              <tr>
                <td>Дата регистрации:</td>
                <td>{moment(form?.createdAt).format("DD.MM.YYYY")}</td>
              </tr>
              {form?.data?.type != "LLC" && form?.data?.type != "IP" && (
                <tr>
                  <td>Дата рождения:</td>
                  <td>
                    {form?.birthday
                      ? moment(form?.birthday).format("DD.MM.YYYY")
                      : "―"}
                  </td>
                </tr>
              )}
              <tr>
                <td>Город:</td>
                <td>{form?.data?.city}</td>
              </tr>
              <tr>
                <td>Номер телефона:</td>
                <td>
                  {form?.phone
                    ? form?.phone.replace(
                      /^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/,
                      "+7 ($2) $3-$4-$5"
                    )
                    : "―"}
                </td>
              </tr>
              <tr>
                <td>Адрес эл. почты:</td>
                <td>{form?.email ? form?.email : "―"}</td>
              </tr>
              {form.data?.type != "individual" && (
                <tr>
                  <td>ИНН:</td>
                  <td>{form?.data?.INN}</td>
                </tr>
              )}
              {form.data?.type == "IP" && (
                <tr>
                  <td>ОГРНИП:</td>
                  <td>{form?.data?.OGRNIP}</td>
                </tr>
              )}
              {form.data?.type == "LLC" && (
                <tr>
                  <td>ОГРН:</td>
                  <td>{form?.data?.OGRN}</td>
                </tr>
              )}
              {form.data?.type == "LLC" && (
                <tr>
                  <td>Юридический адрес:</td>
                  <td>{form?.data?.legalAddress}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {form?.status === -1 && form?.blockedEnd && (
          <p className="text-danger mb-3 fs-09">
            Блокировка до {moment(form.blockedEnd).format("DD.MM.YYYY")}
          </p>
        )}

        {form?.status === -1 ? (
          <Button
            className="btn btn-primary w-100"
            onClick={() => handleSubmitBlocked(onSubmitBlocked(1))}
          >
            Разблокировать
          </Button>
        ) : (
          <Button
            className="btn btn-danger w-100"
            onClick={() => setBlockedShow(true)}
          >
            Заблокировать
          </Button>
        )}
        {form?.status === 0 && (
          <Button
            className="btn btn-primary w-100 mt-4 "
            onClick={() => handleSubmitBlocked(onSubmitBlocked(1))}
          >
            Верифицировать
          </Button>
        )}
        <Button
          className="btn btn-danger w-100  mt-4"
          onClick={() => setModalDelete({ show: !modalDelete.show, id: form.id })}
        >
          Удалить аккаунт
        </Button>

        < Row md={3} className="mt-4 g-4">
          <Col className="align-items-justify pb-3">
            <div className="h-100 d-flex flex-column justify-content-center px-4">
              <h2 className="fw-6 mb-0">{form?.cash ?? 0}</h2>
              <p className="fs-09 text-muted">Баланс</p>
            </div>
          </Col>
          <Col className="align-items-justify pb-3">
            <div className="h-100 d-flex flex-column justify-content-center px-4">
              <h2 className="fw-6 mb-0">{form?.product ?? 0}</h2>
              <p className="fs-09 text-muted">Объявлений</p>
            </div>
          </Col>
          <Col className="align-items-justify pb-3">
            <div className="h-100 d-flex flex-column justify-content-center px-4">
              <h2 className="fw-6 mb-0">{form?.report ?? 0}</h2>
              <p className="fs-09 text-muted">Жалобы</p>
            </div>
          </Col>
        </Row>
        {role == 1 &&
          <Row className="mt-3 mb-3">
            <Col md={6}>
              <div >
                <Input
                  value={total}
                  label="Сумма"
                  type="number"
                  onChange={(e) => setTotal(e)}
                />
              </div>
            </Col>
            <Col md={6}>
              <Button
                className="btn-primary"
                onClick={onCash}
              >
                Изменить баланс
              </Button>
            </Col>
          </Row>
        }
        <CustomModal
          title="Блокировка пользователя"
          show={blockedShow}
          setShow={(e) => setBlockedShow(e)}
          footer={
            <>
              <Button className=" me-3" onClick={() => setBlockedShow(false)}>
                Отмена
              </Button>
              <Button
                className="btn-danger"
                onClick={() => handleSubmitBlocked(onSubmitBlocked(-1))}
              >
                Заблокировать
              </Button>
            </>
          }
        >
          <Input
            label="Окончание блокировки"
            type="datetime-local"
            name="blockedEnd"
            errors={errorsBlocked}
            defaultValue={form.nickname}
            register={registerBlocked}
          />
        </CustomModal>

        {/* <Tabs defaultActiveKey="edit" className="custom-tabs mb-3" fill>
          <Tab eventKey="edit" title="Профиль">
            
          </Tab>
          <Tab eventKey="session" title="Сессии">
            <div className="mb-4">
              <DataTable
                columns={sessionColumns}
                data={sessions.items}
                header={
                  <>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h5 className="fw-7">Сессии</h5>
                      </div>
                    </div>
                  </>
                }
              />
            </div>
            <DataTable
              columns={historiesColumns}
              data={histories.items}
              pagination={histories.pagination}
              header={
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h5 className="fw-7">История</h5>
                    </div>
                  </div>
                </>
              }
            />
            <CustomModal
              title={`Удаление ${
                modalSessionDelete.id ? "#" + modalSessionDelete.id : ""
              }`}
              show={modalSessionDelete.show}
              setShow={(e) => setModalSessionDelete({ show: e, id: false })}
              footer={
                <>
                  <Button
                    className=" me-3"
                    onClick={(e) =>
                      setModalSessionDelete({
                        show: !modalSessionDelete.show,
                        id: false,
                      })
                    }
                  >
                    Отмена
                  </Button>
                  <Button
                    className="btn-primary"
                    onClick={() =>
                      modalSessionDelete.id &&
                      onDeleteSession(modalSessionDelete.id)
                    }
                  >
                    Удалить
                  </Button>
                </>
              }
            >
              Вы точно хотите удалить сессию?
            </CustomModal>
          </Tab>
        </Tabs> */}
        {form?.report && form?.report > 0 &&
          <Reports userId={userId} />
        }
        <CustomModal
          title={
            "Удаление аккаунта"
          }
          show={modalDelete.show}
          setShow={(e) => setModalDelete({ show: e, id: false })}
          footer={
            <>
              <Button
                className="me-3"
                onClick={() =>
                  setModalDelete({ show: !modalDelete.show, id: false })
                }
              >
                Отмена
              </Button>
              <Button
                className="btn-primary"
                onClick={() => onDelete(modalDelete.id)}
              >
                Удалить
              </Button>
            </>
          }
        >
          Вы точно хотите удалить этот аккаунт?
        </CustomModal>
      </section>
    </Container >
  );
};

export default EditUser;
