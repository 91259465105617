import moment from "moment";
import React, { useCallback, useLayoutEffect, useState } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import { getProduct, statusProduct } from "../../services/product";

const treeAll = (item, options) => {
  let j = 0;
  let spanOpt = [];
  spanOpt[j] = item?.option;
  let optId = item?.option;
  while (optId?.parent) {
    optId = options.find((e) => e.id === optId?.parent);
    j++;
    spanOpt[j] = optId;
  }
  spanOpt = spanOpt.reverse();
  return spanOpt;
};

const EditProduct = () => {
  const { productId } = useParams();
  const [loading, setLoading] = useState(true);
  const [statusShow, setStatusShow] = useState(false);
  const [statusRejectShow, setStatusRejectShow] = useState(false);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });
  const form = useWatch({ control });

  const getData = () => {
    getProduct(productId)
      .then((res) => reset(res))
      .finally(() => setLoading(false));
  };

  useLayoutEffect(() => {
    getData();
  }, [productId]);

  // const onSubmit = useCallback((data) => {
  //   editProduct(data)
  //     .then(() => NotificationManager.success("Данные успешно обновлены"))
  //     .catch((err) =>
  //       NotificationManager.error(
  //         err?.response?.data?.error ?? "Ошибка при сохранении"
  //       )
  //     );
  // }, []);

  const onSubmitStatus = useCallback(
    (status) => {
      statusProduct({ ...form, status })
        .then((res) => {
          NotificationManager.success(
            res.status === -1
              ? "Объявление заблокировано"
              : res.status === 1
                ? "Объявление опубликовано"
                : res.status === 0 && "Объявление добавлено в архив"
          );
          res.status === -1 && setStatusShow(false);
          res.status === 0 && setStatusRejectShow(false);
          reset(res);
        })
        .catch((err) =>
          NotificationManager.error(
            err?.response?.data?.error ?? "Ошибка при сохранении"
          )
        );
    },
    [form]
  );

  if (loading) {
    return <Loader full />;
  }

  if (!form?.id) {
    return (
      <>
        <Meta title="Редактировать объявление" />
        <Info>
          <svg
            className="mb-3"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.32"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
              fill="#999"
            />
            <path
              d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
              fill="#999"
            />
          </svg>
          <h3>Такое объявление не существует</h3>
        </Info>
      </>
    );
  }

  return (
    <Container fluid={true}>
      <Meta title="Редактировать объявление" />
      <section className="box">
        <div>
          <Link
            to="/products/ads"
            className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
          >
            <IoChevronBackOutline className="me-2" size={18} /> Назад к
            объявлениям
          </Link>
        </div>

        <div className="d-flex align-items-start mb-3">
          <div className="pe-3 mt-1">
            <div className="position-relative">
              <a
                href={process.env.REACT_APP_SITE_URL + "/offer/" + form.uid}
                target="_blank"
                className="d-flex align-items-center"
              >
                {form.cover ? (
                  <img
                    src={getImageURL({
                      path: form.cover,
                      type: "product/cover",
                      size: "full",
                    })}
                    width={150}
                  />
                ) : (
                  <img width={150} src="/images/img-replacement.jpg" />
                )}
              </a>
            </div>
          </div>
          <div>
            <p>Просмотров: {form.view}</p>
            <p>Пользователь: {form.user.firstName ?? form.user.email}</p>

            <Badge
              className="mb-2"
              bg={
                form.status === 0
                  ? "secondary"
                  : form.status === 1
                    ? "success"
                    : form.status === 2
                      ? "warning"
                      : form.status === -1 && "danger"
              }
            >
              {form.status === 0
                ? "Архив"
                : form.status === 1
                  ? "Активен"
                  : form.status === 2
                    ? "Модерация"
                    : form.status === -1 && "Отклонен"}
            </Badge>
            {form.blockedComment && (
              <p className="text-danger fs-09 mb-2">{form.blockedComment}</p>
            )}

            <div className="mb-2">
              {form?.category?.params &&
                form?.category?.params.length > 0 &&
                [...form?.category?.params]
                  .sort((a, b) => a.id - b.id)
                  .map((param, i) => {
                    let spanOpt = treeAll(form?.options[i], param?.options);
                    if (spanOpt && form?.options[i]) {
                      return (
                        <p>
                          <span>{param?.title}</span>
                          {spanOpt &&
                            spanOpt.length > 0 &&
                            spanOpt.map((item) => {
                              return (
                                <>
                                  <span className="me-2">,</span>
                                  <span>{item?.title}</span>
                                </>
                              );
                            })}
                        </p>
                      );
                    }
                  })}
            </div>
            <div className="mb-3">
              <p className="fs-09">
                <b>UID</b>: {form?.uid}
              </p>
            </div>
            <div className="mb-3">
              <p className="fs-09">
                <b>Заголовок</b>: {form?.title}
              </p>
            </div>

            <div className="mb-3">
              <p className="fs-09">
                <b>Описание</b>: {form?.desc}
              </p>
            </div>
            {form?.categoryId == 1 && <>
              {form?.category?.title && (
                <p className="fs-09">
                  <b>Категория</b>: {form.category.title}
                </p>
              )}
              {form?.data?.franchiseDesc && (
                <p className="fs-09">
                  <b>Описание франшизы</b>: {form.data.franchiseDesc}
                </p>
              )}
              {form?.data?.franchiseBenefits && (
                <p className="fs-09">
                  <b>Преимущества франшизы</b>: {form.data.franchiseBenefits}
                </p>
              )}
              {form?.data?.termsOfCooperation && (
                <p className="fs-09">
                  <b>Условия сотрудничества</b>: {form.data.termsOfCooperation}
                </p>
              )}
              {form?.data?.businessPlan && (
                <p className="fs-09">
                  <b>Бизнес план</b>: {form.data.businessPlan}
                </p>
              )}
              {form.medias?.length > 0 && (
                <div>
                  <p className="mt-2 mb-2 fw-5">Фотогалерея</p>
                  {form.medias.map((e) => (
                    <div className="d-inline-block pb-3 pe-3">
                      <img
                        src={getImageURL({
                          path: e.media,
                          type: "product/media",
                          size: "full",
                        })}
                        width={120}
                        height={120}
                      />
                    </div>
                  ))}
                </div>
              )}
              {form?.data?.video && (
                <p className="fs-09">
                  <b>Ссылка на видео</b>: {form.data.video}
                </p>
              )}
              {form?.data?.city && (
                <p className="fs-09">
                  <b>Город</b>: {form.data.city}
                </p>
              )}
              {form?.data?.startingInvestmentsFrom && (
                <p className="fs-09">
                  <b>Стартовые инвестиции от</b>: {form.data.startingInvestmentsFrom}
                </p>
              )}
              {form?.data?.lumpSumPayment && (
                <p className="fs-09">
                  <b>Паушальный взнос</b>: {form.data.lumpSumPayment}
                </p>
              )}
              {form?.data?.royalty && (
                <p className="fs-09">
                  <b>Роялти</b>: {form.data.royalty}
                </p>
              )}
              {form?.data?.paybackPeriodFrom && (
                <p className="fs-09">
                  <b>Срок окупаемости от</b>: {form.data.paybackPeriodFrom}
                </p>
              )}
              {form?.data?.estimatedProfit && (
                <p className="fs-09">
                  <b>Пред. прибыль</b>: {form.data.estimatedProfit}
                </p>
              )}


              {form?.data?.yearTheCompanyWasFounded && (
                <p className="fs-09">
                  <b>Год основания компании</b>: {form.data.yearTheCompanyWasFounded}
                </p>
              )}

              {form?.data?.numberOfOwnPoints && (
                <p className="fs-09">
                  <b>Количество собственных точек</b>: {form.data.numberOfOwnPoints}
                </p>
              )}
              {form?.data?.numberOfFranchisesSold && (
                <p className="fs-09">
                  <b>Количество проданных франшиз</b>: {form.data.numberOfFranchisesSold}
                </p>
              )}
            </>}
            {form?.categoryId == 2 && <>
              {form?.category?.title && (
                <p className="fs-09">
                  <b>Категория</b>: {form.category.title}
                </p>
              )}
              {form?.data?.termsOfSale && (
                <p className="fs-09">
                  <b>Условия продажи</b>: {form.data.termsOfSale}
                </p>
              )}

              {form?.data?.businessPlan && (
                <p className="fs-09">
                  <b>Бизнес план</b>: {form.data.businessPlan}
                </p>
              )}
              {form.medias?.length > 0 && (
                <div>
                  <p className="mt-2 mb-2 fw-5">Фотогалерея</p>
                  {form.medias.map((e) => (
                    <div className="d-inline-block pb-3 pe-3">
                      <img
                        src={getImageURL({
                          path: e.media,
                          type: "product/media",
                          size: "full",
                        })}
                        width={120}
                        height={120}
                      />
                    </div>
                  ))}
                </div>
              )}
              {form?.data?.video && (
                <p className="fs-09">
                  <b>Ссылка на видео</b>: {form.data.video}
                </p>
              )}
              {form?.data?.city && (
                <p className="fs-09">
                  <b>Город</b>: {form.data.city}
                </p>
              )}
              {form?.data?.payback && (
                <p className="fs-09">
                  <b>Окупаемость</b>: {form.data.payback}
                </p>
              )}
              {form?.data?.amountOfPoints && (
                <p className="fs-09">
                  <b>Количество точек</b>: {form.data.amountOfPoints}
                </p>
              )}
              {form?.data?.businessCost && (
                <p className="fs-09">
                  <b>Стоимость</b>: {form.data.businessCost}
                </p>
              )}
              {form?.data?.turnoverPerMonth && (
                <p className="fs-09">
                  <b>Оборот в месяц</b>: {form.data.turnoverPerMonth}
                </p>
              )}
              {form?.data?.netProfit && (
                <p className="fs-09">
                  <b>Чистая прибыль</b>: {form.data.netProfit}
                </p>
              )}
            </>}
            {form?.categoryId == 3 && <>
              {form?.category?.title && (
                <p className="fs-09">
                  <b>Категория</b>: {form.category.title}
                </p>
              )}
              {form?.data?.estimatedTermsOfCooperation && (
                <p className="fs-09">
                  <b>Пред. условия сотрудничества</b>: {form.data.estimatedTermsOfCooperation}
                </p>
              )}

              {form?.data?.about && (
                <p className="fs-09">
                  <b>О себе</b>: {form.data.about}
                </p>
              )}
              {form.medias?.length > 0 && (
                <div>
                  <p className="mt-2 mb-2 fw-5">Фотогалерея</p>
                  {form.medias.map((e) => (
                    <div className="d-inline-block pb-3 pe-3">
                      <img
                        src={getImageURL({
                          path: e.media,
                          type: "product/media",
                          size: "full",
                        })}
                        width={120}
                        height={120}
                      />
                    </div>
                  ))}
                </div>
              )}

              {form?.data?.city && (
                <p className="fs-09">
                  <b>Город</b>: {form.data.city}
                </p>
              )}
              {form?.data?.possibleInvestments && (
                <p className="fs-09">
                  <b>Возможные инвестиции</b>: {form.data.possibleInvestments}
                </p>
              )}

              {form?.data?.payback && (
                <p className="fs-09">
                  <b>Окупаемость</b>: {form.data.payback}
                </p>
              )}
            </>}
            {form?.categoryId == 4 && <>
              {form?.category?.title && (
                <p className="fs-09">
                  <b>Категория</b>: {form.category.title}
                </p>
              )}

              {form?.data?.termsOfCooperation && (
                <p className="fs-09">
                  <b>Условия сотрудничества</b>: {form.data.termsOfCooperation}
                </p>
              )}
              {form?.data?.businessPlan && (
                <p className="fs-09">
                  <b>Бизнес план</b>: {form.data.businessPlan}
                </p>
              )}
              {form?.data?.about && (
                <p className="fs-09">
                  <b>О себе</b>: {form.data.about}
                </p>
              )}
              {form.medias?.length > 0 && (
                <div>
                  <p className="mt-2 mb-2 fw-5">Фотогалерея</p>
                  {form.medias.map((e) => (
                    <div className="d-inline-block pb-3 pe-3">
                      <img
                        src={getImageURL({
                          path: e.media,
                          type: "product/media",
                          size: "full",
                        })}
                        width={120}
                        height={120}
                      />
                    </div>
                  ))}
                </div>
              )}

              {form?.data?.city && (
                <p className="fs-09">
                  <b>Город</b>: {form.data.city}
                </p>
              )}

              {form?.data?.requiredInvestments && (
                <p className="fs-09">
                  <b>Требуемые инвестиции</b>: {form.data.requiredInvestments}
                </p>
              )}

              {form?.data?.estimatedProfitMeme && (
                <p className="fs-09">
                  <b>Предполагаемая прибыль / мес</b>:{" "}
                  {form.data.estimatedProfitMeme}
                </p>
              )}


              {form?.data?.payback && (
                <p className="fs-09">
                  <b>Окупаемость</b>: {form.data.payback}
                </p>
              )}


              {form?.data?.projectStage && (
                <p className="fs-09">
                  <b>Статус проекта</b>: {form.data.projectStage}
                </p>
              )}

            </>}
            {form?.categoryId == 5 && <>
              {form?.category?.title && (
                <p className="fs-09">
                  <b>Категория</b>: {form.category.title}
                </p>
              )}

              {form?.data?.termsOfCooperation && (
                <p className="fs-09">
                  <b>Условия сотрудничества</b>: {form.data.termsOfCooperation}
                </p>
              )}
              {form?.data?.businessPlan && (
                <p className="fs-09">
                  <b>Бизнес план</b>: {form.data.businessPlan}
                </p>
              )}
              {form?.data?.about && (
                <p className="fs-09">
                  <b>О себе</b>: {form.data.about}
                </p>
              )}
              {form.medias?.length > 0 && (
                <div>
                  <p className="mt-2 mb-2 fw-5">Фотогалерея</p>
                  {form.medias.map((e) => (
                    <div className="d-inline-block pb-3 pe-3">
                      <img
                        src={getImageURL({
                          path: e.media,
                          type: "product/media",
                          size: "full",
                        })}
                        width={120}
                        height={120}
                      />
                    </div>
                  ))}
                </div>
              )}

              {form?.data?.city && (
                <p className="fs-09">
                  <b>Город</b>: {form.data.city}
                </p>
              )}

              {form?.data?.requiredInvestments && (
                <p className="fs-09">
                  <b>Требуемые инвестиции</b>: {form.data.requiredInvestments}
                </p>
              )}

              {form?.data?.estimatedProfitMeme && (
                <p className="fs-09">
                  <b>Предполагаемая прибыль / мес</b>:{" "}
                  {form.data.estimatedProfitMeme}
                </p>
              )}


              {form?.data?.payback && (
                <p className="fs-09">
                  <b>Окупаемость</b>: {form.data.payback}
                </p>
              )}


              {form?.data?.projectStage && (
                <p className="fs-09">
                  <b>Статус проекта</b>: {form.data.projectStage}
                </p>
              )}

            </>}
          </div>
        </div>

        {form?.statusEnd && (
          <p className="text-danger mb-3 fs-09">
            Блокировка до {moment(form.statusEnd).format("DD.MM.YYYY")}
          </p>
        )}
        <Row className="gx-2">
          {/* <Col md={12}>
            <Button
              className="btn mb-3 btn-primary w-100"
              disabled={!isValid}
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить
            </Button>
          </Col> */}
          <Col md={4}>
            <Button
              className="btn mb-3 btn-primary w-100"
              disabled={form.status === 1}
              onClick={() => handleSubmit(onSubmitStatus(1))}
            >
              Принять
            </Button>
          </Col>
          <Col md={4}>
            <Button
              className="btn mb-3 btn-gray w-100"
              onClick={() => setStatusRejectShow(true)}
            >
              Отклонить
            </Button>
          </Col>
          <Col md={4}>
            {form?.status === -1 || form?.statusEnd ? (
              <Button
                className="btn mb-3 btn-primary w-100"
                onClick={() => handleSubmit(onSubmitStatus(0))}
              >
                Разблокировать
              </Button>
            ) : (
              <Button
                className="btn mb-3 btn-danger w-100"
                onClick={() => setStatusShow(true)}
              >
                Заблокировать
              </Button>
            )}
          </Col>
        </Row>

        <CustomModal
          title="Блокировка объявления"
          show={statusShow}
          setShow={(e) => setStatusShow(e)}
          footer={
            <>
              <Button className=" me-3" onClick={() => setStatusShow(false)}>
                Отмена
              </Button>
              <Button
                className="btn-danger"
                onClick={() => handleSubmit(onSubmitStatus(-1))}
              >
                Заблокировать
              </Button>
            </>
          }
        >
          <textarea rows={5} {...register("blockedComment")} />
        </CustomModal>

        <CustomModal
          title="Отклонение объявления"
          show={statusRejectShow}
          setShow={(e) => setStatusRejectShow(e)}
          footer={
            <>
              <Button
                className=" me-3"
                onClick={() => setStatusRejectShow(false)}
              >
                Отмена
              </Button>
              <Button
                className="btn-gray"
                onClick={() => handleSubmit(onSubmitStatus(0))}
              >
                Отклонить
              </Button>
            </>
          }
        >
          <textarea rows={5} {...register("blockedComment")} />
        </CustomModal>

        {/* <Tabs defaultActiveKey="edit" className="custom-tabs mb-3" fill>
          <Tab eventKey="edit" title="Профиль">
            
          </Tab>
          <Tab eventKey="session" title="Сессии">
            <div className="mb-4">
              <DataTable
                columns={sessionColumns}
                data={sessions.items}
                header={
                  <>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h5 className="fw-7">Сессии</h5>
                      </div>
                    </div>
                  </>
                }
              />
            </div>
            <DataTable
              columns={historiesColumns}
              data={histories.items}
              pagination={histories.pagination}
              header={
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h5 className="fw-7">История</h5>
                    </div>
                  </div>
                </>
              }
            />
            <CustomModal
              title={`Удаление ${
                modalSessionDelete.id ? "#" + modalSessionDelete.id : ""
              }`}
              show={modalSessionDelete.show}
              setShow={(e) => setModalSessionDelete({ show: e, id: false })}
              footer={
                <>
                  <Button
                    className=" me-3"
                    onClick={(e) =>
                      setModalSessionDelete({
                        show: !modalSessionDelete.show,
                        id: false,
                      })
                    }
                  >
                    Отмена
                  </Button>
                  <Button
                    className="btn-primary"
                    onClick={() =>
                      modalSessionDelete.id &&
                      onDeleteSession(modalSessionDelete.id)
                    }
                  >
                    Удалить
                  </Button>
                </>
              }
            >
              Вы точно хотите удалить сессию?
            </CustomModal>
          </Tab>
        </Tabs> */}
      </section>
    </Container>
  );
};

export default EditProduct;
